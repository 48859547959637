<template>
    <!-- <div class="flex-1 justify-center flex items-center"> -->
        <!-- <section> -->
        
        <div
            v-if="showForms"
            class="w-full grid h-auto  grid-cols-2 flex-shrink xl:grid-cols-2 items-start content-start  p-4 lg:p-6 gap-4 xl:gap-8"
        >
            <!-- <div class="flex justify-center align-top rounded-lg self-stretch hover:shadow-xl"> -->
                <div class="card   bg-base-100 shadow  border-primary border text-base-content"  v-for="form in form_preview_list" :key="form" >
                    <div class="card-body p-4 lg:p-6">
                        <h2 class="card-title text-base-content-600">{{form === 'Candidate' ? 'Subject': form}} Form</h2>
                        <div class="flex-grow max-h-28">
                            <p v-if="form == 'Candidate'">This form will be seen by Subject as it is.</p>
                            <p v-else>This form will be visible by {{form === 'Candidate' ? 'Subject': form}} </p>
                        </div>
                        <div class="card-actions justify-end">
                            <Button  class="w-full capitalize"  type="primary"  :text="`click to preview ${form === 'Candidate' ? 'Subject': form} form`" @click="previewForm(form)" />
                        </div>
                    </div>
                </div>
            <!-- </div> -->
            <!-- <div class="flex justify-center rounded-lg h-full p-2">
                <div class="card w-96 bg-base-100 shadow-xl">
                    <div class="card-body">
                        <h2 class="card-title">Client Form</h2>
                        <p>This form will be visible by Client.</p>
                        <div class="card-actions justify-end">
                            <Button class="w-full capitalize" type="primary" text="click to preview Client form" @click="previewForm('Client')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center rounded-lg h-full p-2">
                <div class="card w-96 bg-base-100 shadow-xl">
                    <div class="card-body">
                        <h2 class="card-title">Referee Form</h2>
                        <p>This form will be visible by Referee.</p>
                        <div class="card-actions justify-end">
                            <Button class="w-full capitalize" type="primary" text="click to preview Referee form" @click="previewForm('Referee')" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center rounded-lg h-full p-2">
                <div class="card w-96 bg-base-100 shadow-xl">
                    <div class="card-body">
                        <h2 class="card-title">Screener Form</h2>
                        <p>This form will be visible by Screener.</p>
                        <div class="card-actions justify-end">
                            <Button class="w-full capitalize" type="primary" text="click to preview Screener form" @click="previewForm('Screener')" />
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <Empty
            v-else
            text="No form exists for this user, please configure the forms"
            classList="text-lg"
            :showBtn="false"
        />
        <!-- </section> -->
    <!-- </div> -->
</template>

<script>
import Button from "@/components/button";
import Empty from "@shared/empty";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "FormPreview",
    props: {
        // Selected entity type from the parent component:
        entityType: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form_preview_list: ['Subject','Client','Referee','Analyst'],
            form: {},
            role: [],
        };
    },
    components: {
        Button,
        Empty,
    },
    computed: {
        ...mapGetters([
            "getRoles",
            "getPackageSections",
            "getPackageChecks",
            "getPackageForms",
        ]),
        getDisable(){
            return this.$route.params?.action == 'view';
        },
        showForms() {
            return this.getPackageSections.some((el) => !!el.fields?.length)
                || !!this.getPackageChecks?.length
                || !!this.getPackageForms?.length
        },
    },
    methods: {
        ...mapActions(["fetchRoles"]),
        async previewForm(title) {
            // if(this.$route.params?.action == 'view'){
            //     return 
            // }
            // let roles = this.getRoles;
            console.log(this.getRoles, "roles")
            // sessionStorage.setItem('role',title);
            if (!this.getRoles && !this.getRoles.length) {
                await this.fetchRoles();
            }
            this.role = this.getRoles.find((role) => role.title === title);
            const package_id = this.$route.params.packageName;
            try {
                const routeData = this.$router.resolve({
                    name: "Form Builder",
                    params: { package: package_id },
                    query: {
                        role: this.role.id,
                        entity_type: this.entityType.id,
                    },
                });
                window.open(routeData.href, "_blank");
                
            } catch (error) {
                this.$toast.error('Something went wrong!')
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
